import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import { Col, Row, Table } from 'react-bootstrap';
import lodash from 'lodash';
import { IBCMCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { BCMCharacter } from '../../modules/bcm/common/components/bcm-character';
import { BCMStat } from '../../modules/bcm/common/components/bcm-stat';

interface IBCMCharacterNodes {
  nodes: IBCMCharacter[];
}

interface IBCMCharacterEntry {
  allCharacters: IBCMCharacterNodes;
}

interface IProps {
  data: IBCMCharacterEntry;
}

const BCMCharsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          }
        ]
      },
      {
        key: 'attribute',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Power',
            tooltip: 'Power',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_power.png"
                width={24}
                alt="Power"
              />
            )
          },
          {
            value: 'Sense',
            tooltip: 'Sense',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_sense.png"
                width={24}
                alt="Sense"
              />
            )
          },
          {
            value: 'Technique',
            tooltip: 'Technique',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_tech.png"
                width={24}
                alt="Technique"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Chaos',
            tooltip: 'Chaos',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_chaos.png"
                width={24}
                alt="Chaos"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_attacker.png"
                width={24}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Debuffer',
            tooltip: 'Debuffer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_debuffer.png"
                width={24}
                alt="Debuffer"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_defender.png"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'Healer',
            tooltip: 'Healer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_healer.png"
                width={24}
                alt="Healer"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_supporter.png"
                width={24}
                alt="Supporter"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.attribute && activeFilters.attribute !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.attribute.toLowerCase() ===
            activeFilters.attribute.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === 'stat_hp') {
      filtered = filtered.sort((a, b) => (a.stats.hp < b.stats.hp ? 1 : -1));
    } else if (sortOption === 'stat_atk') {
      filtered = filtered.sort((a, b) => (a.stats.atk < b.stats.atk ? 1 : -1));
    } else if (sortOption === 'stat_def') {
      filtered = filtered.sort((a, b) => (a.stats.def < b.stats.def ? 1 : -1));
    } else if (sortOption === 'stat_speed') {
      filtered = filtered.sort((a, b) =>
        a.stats.speed < b.stats.speed ? 1 : -1
      );
    } else if (sortOption === 'stat_matk') {
      filtered = filtered.sort((a, b) =>
        a.stats.matk < b.stats.matk ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'stat_atk', label: 'Sort by ATK' },
    { value: 'stat_matk', label: 'Sort by MATK' },
    { value: 'stat_hp', label: 'Sort by HP' },
    { value: 'stat_def', label: 'Sort by DEF' },
    { value: 'stat_speed', label: 'Sort by Speed' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke character-stats-ag'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/blackclover/categories/cat_stats.png"
            alt="Stats"
          />
        </div>
        <div className="page-details">
          <h1>Characters stats</h1>
          <h2>
            A quick way to compare stats of characters in Black Clover Mobile.
          </h2>
          <p>
            Last updated: <strong>30/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar bcm">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row>
        <Col>
          <Table className="stats-table" striped bordered responsive>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="name"
                  onClick={() => setSortOption('+fullName')}
                >
                  Name
                  {sortOption && sortOption === '+fullName' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stat_atk')}
                >
                  <BCMStat stat="atk" />
                  {sortOption && sortOption === 'stat_atk' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stat_matk')}
                >
                  <BCMStat stat="matk" />
                  {sortOption && sortOption === 'stat_matk' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stat_hp')}
                >
                  <BCMStat stat="hp" />
                  {sortOption && sortOption === 'stat_hp' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stat_def')}
                >
                  <BCMStat stat="def" />
                  {sortOption && sortOption === 'stat_def' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stat_speed')}
                >
                  <BCMStat stat="speed" />
                  {sortOption && sortOption === 'stat_speed' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleCharacters.map((emp, index) => (
                <>
                  {emp.stats ? (
                    <>
                      <tr key={index}>
                        <th scope="row" className="char">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                          />
                          <span className="name">{emp.name}</span>
                        </th>
                        <td className="stat">
                          {emp.stats.atk ? emp.stats.atk : 0}
                        </td>
                        <td className="stat">
                          {emp.stats.matk ? emp.stats.matk : 0}
                        </td>
                        <td className="stat">
                          {emp.stats.hp ? emp.stats.hp : 0}
                        </td>
                        <td className="stat">
                          {emp.stats.def ? emp.stats.def : 0}
                        </td>
                        <td className="stat">
                          {emp.stats.speed ? emp.stats.speed : 0}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/blackclover/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default BCMCharsPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters stats | Black Clover M | Prydwen Institute"
    description="A quick way to compare stats of characters in Black Clover Mobile."
    game="bcm"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulBlackCloverCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        squad
        attribute
        class
        rarity
        isCharacterSeasonal
        season
        stats {
          power
          atk
          matk
          def
          hp
          acc
          dmgres
          crit
          critdmg
          critres
          speed
          pen
          end
        }
      }
    }
  }
`;
